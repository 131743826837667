*,
::before,
::after {
  box-sizing: border-box;
}

body {
  background-color: #050a31;
  color: #fff;
  font-size: 1rem;
  font-family: 'kyrillasansserif', Arial, Helvetica, sans-serif;
  line-height: 1.25;
}

p {
  margin: 0;
}

button {
  padding: 0;
}

img {
  max-width: 100%;
}
